import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject, Injectable,
    OnDestroy,
    OnInit, Output,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {GeproRestTO} from '../index/service/Model/GeproRestTO';
import {GeproService} from '../index/service/gepro.service';
import {StatusTypeRestTO} from '../index/service/Model/StatusTypeRestTO';
import * as Constants from '../../config/constants';
import {
    STATUS_NAME_FREIGEGEBEN,
    STATUS_NAME_NACHBEARBEITUNG,
    STATUS_NAME_RUECKFRAGE_BEANTWORTET,
    STATUS_NAME_RUECKFRAGE_GESTELLT,
    STATUS_NAME_ZUR_FREIGABE
} from '../../config/constants';
import {Subject, Subscription} from 'rxjs';
import {UserinfoService} from '../index/service/userinfo.service';
import {UserMessagesService} from '../common/messages-area/messages-area/service/user-messages.service';
import {Pages} from '../navigation/pages';
import {MoveGeproReferatService} from './move-gepro-referat/service/move-gepro-referat.service';
import {Filter, GeproFilterComponent} from './gepro-filter/gepro-filter.component';
import {StatesService} from '../../services/states.service';
import {WorkListService} from '../../services/work-list.service';
import {DenyGeproService} from './deny-gepro/service/deny-gepro.service';
import {VisibilityService} from '../../services/visibility.service';
import {GeproListWithMessageRestTO} from '../index/service/Model/GeproListWithMessageRestTO';
import {GeproFilterVisibilityService} from './gepro-filter/gepro-filter-visibility.service';
import {SortEvent} from '@allianz/ngx-ndbx/table';
import {PlatformLocation} from '@angular/common';
import {GeproFilterStatisticsService} from '../index/service/gepro-filter-statistics.service';
import {HttpParams} from '@angular/common/http';
import {GeproFilterStatisticsInterface} from '../index/service/Model/GeproFilterStatisticsRestTO';
import {GeproFilterRequest} from './gepro-filter/entity/gepro-filter-request';
import isEqual from 'lodash/isEqual';
import {SetFilterCountService} from '../../services/set-filter-count.service';
import {VorgangTabService} from '../index/service/vorgang-tab.service';
import {HEADER_NAME_RELATIONSHIP} from '../../config/constants';
import {StellvertreterTO} from '../index/service/Model/StellvertreterTO';
import {StellvertreterService} from '../index/service/stellvertreter.service';
import {WorkListTabsComponent} from './work-list-tabs/work-list-tabs.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NxDatepickerIntl} from "@allianz/ngx-ndbx/datefield";
import {Wiedervorlage, WiedervorlageService} from "../index/service/wiedervorlage.service";
import {CsvUtils} from "../../csvUtils.global";
import * as moment from 'moment';

@Injectable()
export class MyIntl extends NxDatepickerIntl {
    switchToMonthViewLabel = 'Wählen Sie ein Datum';
    switchToMultiYearViewLabel = 'Wählen Sie ein Datum';
}
@Component({
    selector: 'app-work-list',
    templateUrl: './work-list.component.html',
    styleUrls: ['./work-list.component.scss']
})
export class WorkListComponent implements OnInit, AfterViewChecked, OnDestroy {

    currentPage: number;
    elementsPerPage: number;
    sortHeader = 'lastStatusChange';
    sortDirection = 'desc';
    stvListDropdown: StellvertreterTO[];
    selectedStv: StellvertreterTO;

    sortedGepros: any[];
    sortColumn: string = '';
    sortOrder: string = 'default';
    sortOrderName: string = 'default';
    sortOrderVermittler: string = 'default';
    sortOrderMitarbeiterName: string =  'default';
    sortOrderPublished: string =  'default';
    sortOrderChanged: string =  'default';

    @ViewChild(GeproFilterComponent, {read: GeproFilterComponent, static: false}) geproFilterComponent;
    @ViewChild(WorkListTabsComponent, {read: WorkListTabsComponent, static: false}) worklistTabComponent;
    hideFilters = true;
    filterCount = 0;
    lastFiltersEmitted: GeproFilterRequest = null;

    private accumulatedSubscription: Subscription = new Subscription();
    vorgangFilterStatisticsSubject: Subject<Array<GeproFilterStatisticsInterface>>;
    setFiltersSubject: Subject<GeproFilterRequest>;
    showCheckboxColumn: boolean;
    showMarkedColumn: boolean;

    public constructor(private route: ActivatedRoute,
                       private router: Router,
                       private location: PlatformLocation,
                       public userinfoService: UserinfoService,
                       private changeDetectorRef: ChangeDetectorRef,
                       private userMessagesService: UserMessagesService,
                       private moveGeproReferatService: MoveGeproReferatService,
                       private statesService: StatesService,
                       public workListService: WorkListService,
                       public workListServiceCSV: WorkListService,
                       private geproService: GeproService,
                       private geproFilterStatisticsService: GeproFilterStatisticsService,
                       public visibilityService: VisibilityService,
                       public filterVisibilityService: GeproFilterVisibilityService,
                       private denyGeproService: DenyGeproService,
                       private setFilterCountService: SetFilterCountService,
                       private vorgangTabService: VorgangTabService,
                       public dialog : MatDialog,
                       private csvutils : CsvUtils,
                       public wiedervorlageService: WiedervorlageService,
                       private stvService: StellvertreterService) {
        this.currentPage = 1;
        this.elementsPerPage = 25;
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
                history.pushState(null, null, window.location.href);
            });
        this.vorgangFilterStatisticsSubject = new Subject();
        this.setFiltersSubject = new Subject();
        this.showCheckboxColumn = false;
        this.showMarkedColumn = false;
        this.loadStvData();
    }

    public ngOnInit(): void {
        this.handleSubscriptions();
        // this.queryGeprosWithSetParams(); triggered by selected tabs now
        this.showCheckboxColumn = false;
        this.showMarkedColumn = false;
    }

    sortById() {
        this.sortOrderName='default';
        this.sortOrderMitarbeiterName='default';
        this.sortOrderPublished='default';
        this.sortOrderVermittler='default';
        this.sortOrderChanged='default';
        this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
        this.workListService.gepros.sort((a, b) => {
            const comparison = a.id > b.id ? 1 : a.id < b.id ? -1 : 0;
            return this.sortOrder === 'asc' ? comparison : -comparison;
        });
    }

    sortByName() {
        this.sortOrder='default';
        this.sortOrderVermittler='default';
        this.sortOrderMitarbeiterName='default';
        this.sortOrderPublished='default';
        this.sortOrderChanged='default';
        this.sortOrderName = this.sortOrderName === 'asc' ? 'desc' : 'asc';
        this.workListService.gepros.sort((a, b) => {
            const comparison = a.geproType?.name > b.geproType?.name ? 1 : a.geproType?.name < b.geproType?.name ? -1 : 0;
            return this.sortOrderName === 'asc' ? comparison : -comparison;
        });
    }

    sortByVermittler() {
        this.sortOrder='default';
        this.sortOrderMitarbeiterName='default';
        this.sortOrderName='default';
        this.sortOrderPublished='default';
        this.sortOrderChanged='default';
        this.sortOrderVermittler = this.sortOrderVermittler === 'asc' ? 'desc' : 'asc';
        this.workListService.gepros.sort((a, b) => {
            const comparison = this.getMaklerOrStrukturName(a) > this.getMaklerOrStrukturName(b) ? 1 : this.getMaklerOrStrukturName(a) < this.getMaklerOrStrukturName(b) ? -1 : 0;
            return this.sortOrderVermittler === 'asc' ? comparison : -comparison;
        });
    }

    sortByMitarbeiterName() {
        this.sortOrder = 'default';
        this.sortOrderName = 'default';
        this.sortOrderVermittler = 'default';
        this.sortOrderPublished = 'default';
        this.sortOrderChanged = 'default';
        this.sortOrderMitarbeiterName = this.sortOrderMitarbeiterName === 'asc' ? 'desc' : 'asc';

        this.workListService.gepros.sort((a, b) => {
            if ((!a.mitarbeiterName || a.mitarbeiterName === '') && b.mitarbeiterName) {
                return this.sortOrderMitarbeiterName === 'asc' ? -1 : 1;
            }
            if (a.mitarbeiterName && (!b.mitarbeiterName || b.mitarbeiterName === '')) {
                return this.sortOrderMitarbeiterName === 'asc' ? 1 : -1;
            }
            if (a.mitarbeiterName > b.mitarbeiterName) {
                return this.sortOrderMitarbeiterName === 'asc' ? 1 : -1;
            }
            if (a.mitarbeiterName < b.mitarbeiterName) {
                return this.sortOrderMitarbeiterName === 'asc' ? -1 : 1;
            }
            return 0;
        });
    }

    sortByTimestamp() {
        this.sortOrder='default';
        this.sortOrderName='default';
        this.sortOrderMitarbeiterName='default';
        this.sortOrderVermittler='default';
        this.sortOrderChanged='default';
        this.sortOrderPublished = this.sortOrderPublished === 'asc' ? 'desc' : 'asc';
        this.workListService.gepros.sort((a, b) => {
            const comparison = a.publishTimestamp > b.publishTimestamp ? 1 : a.publishTimestamp < b.publishTimestamp ? -1 : 0;
            return this.sortOrderPublished === 'asc' ? comparison : -comparison;
        });
    }

    sortByChanged() {
        this.sortOrder='default';
        this.sortOrderName='default';
        this.sortOrderMitarbeiterName='default';
        this.sortOrderVermittler='default';
        this.sortOrderPublished='default';
        this.sortOrderChanged = this.sortOrderChanged === 'asc' ? 'desc' : 'asc';
        this.workListService.gepros.sort((a, b) => {
            const comparison = a.changeTimestamp > b.changeTimestamp ? 1 : a.changeTimestamp < b.changeTimestamp ? -1 : 0;
            return this.sortOrderChanged === 'asc' ? comparison : -comparison;
        });
    }

    downloadCSV(){
        this.workListService.loadCSVGeproForCurrentUser(this.sortHeader, this.sortDirection);
    }

    private loadStvData() {
        this.stvService.getSelectedStellvertreter().subscribe(stvList => {
                this.stvListDropdown = stvList;
                this.stvListDropdown.forEach(stv => {
                    if (stv.bensl === this.userinfoService.getUser().bensl) {
                        this.selectedStv = stv;
                    }
                });
            },
            error => {
                this.userMessagesService.addErrorMessageString('Fehler beim Laden der Stellvertreter-Daten. ' + error.error.message);
            });
    }

    public queryGeprosWithSetParams(): void {
        this.workListService.loadGeproForCurrentUser(this.currentPage, this.elementsPerPage, this.sortHeader, this.sortDirection);
    }

    sort(sortEvent: SortEvent) {
        console.log("1");
        this.sortHeader = sortEvent.active;
        this.sortDirection = sortEvent.direction;
        this.queryGeprosWithSetParams();
    }

    previousPage(): void {
        console.log("2");
        this.currentPage--;
        this.queryGeprosWithSetParams();
    }

    nextPage(): void {
        console.log("3");
        this.currentPage++;
        this.queryGeprosWithSetParams();
    }

    goToPage(n: number) {
        console.log("4");
        this.currentPage = n;
        this.queryGeprosWithSetParams();
    }

    pageSize(n: number) {
        console.log("5");
        this.elementsPerPage = n;
        this.queryGeprosWithSetParams();
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    private handleSubscriptions(): void {
        this.accumulatedSubscription.add(this.moveGeproReferatService.subscribeToGeproChangeReferatRequests({
            next: request => {
                this.geproService.changeReferatForGepro(request).subscribe(() => {
                    this.vorgangTabService.tabChangedSubject.next();
                });
            }
            , error: error => this.userMessagesService.addErrorMessageString(error.error)
        }));
        this.accumulatedSubscription.add(this.denyGeproService.subscribeToDenyReasonSubject({
            next: request => {
                const geproListWithMessageRestTO: GeproListWithMessageRestTO = request;
                for (const geproId of geproListWithMessageRestTO.geproIdList) {
                        this.geproService.denyGepro(geproId, geproListWithMessageRestTO.reason).subscribe(() => {
                            this.vorgangTabService.tabChangedSubject.next();
                            this.workListService.selectedGepros.clear();
                    });
                }
            }
            , error: error => this.userMessagesService.addErrorMessageString(error.error)
        }));
        this.setFilterCountService.getFilterCountObservable().subscribe(filterCount => this.filterCountChanged(filterCount));
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public displayMitarbeiterColumn(): boolean {
        return this.userinfoService.isRoleReferatsleiter();
    }

    public isAssignableStatusSelected(): boolean {
        return this.lastFiltersEmitted &&
            isEqual(this.lastFiltersEmitted.getStatusTypeFilters().sort(), this.statesService.getStatesForStatusToAssign().sort());
    }

    public isInProgressStatusSelected(): boolean {
        return this.lastFiltersEmitted &&
            isEqual(this.lastFiltersEmitted.getStatusTypeFilters().sort(), this.statesService.getStatesForStatusInProgress().sort());
    }

    public isVibrant(statusType: StatusTypeRestTO): boolean {
        return statusType.id === Constants.statusType_nichtGeroutet
            || statusType.name === STATUS_NAME_RUECKFRAGE_GESTELLT
            || statusType.name === STATUS_NAME_RUECKFRAGE_BEANTWORTET;
    }

    goToGepro(item: GeproRestTO) {
        let commands: Array<any>;
        if (GeproRestTO.isDraft(item)) {
            this.geproService.currentGepro = item;
            commands = ['/' + Pages.PATH_CREATE_GEPRO + '/type', item.id];
        } else {
            commands = ['/' + Pages.PATH_GEPRO_DETAILS, item.id];
        }
        this.router.navigate(commands);
    }

    onFilter(quickFilters: Array<Filter>): void {
        this.currentPage = 1;
        this.workListService.loading=true;
        this.workListService.quickFilters = quickFilters;
        this.setFilterCountService.filterParamsSet(this.getFilterParams());
        console.log("6");
        this.queryGeprosWithSetParams();
        // this.queryGeproStatisticsWithSetParams();
        if (this.lastFiltersEmitted && this.lastFiltersEmitted.getRelationshipFilter() === 'filterNotAssigned') {
            this.showCheckboxColumn = true;
        } else {
            this.showCheckboxColumn = false;
        }
        this.showMarkedColumn = this.isToggleZuteilung();
    }

    resetFilter(): void {
        this.resetFilterSessionStorage();
        this.geproFilterComponent.resetSelectedFilter();
    }

    private resetFilterSessionStorage() {
        sessionStorage.removeItem('filterStatusTypesessionStorage');
        sessionStorage.removeItem('filterGeproTypesessionStorage');
        sessionStorage.removeItem('filterMsn6sessionStorage');
        sessionStorage.removeItem('filter_createdMinDatesessionStorage');
        sessionStorage.removeItem('filter_createdMaxDatesessionStorage');
        sessionStorage.removeItem('filter_modifiedMinDatesessionStorage');
        sessionStorage.removeItem('filter_modifiedMaxDatesessionStorage');
        sessionStorage.removeItem('filterBearbeiterNamesessionStorage');
    }

    isDraft(draftStatus: string): boolean {
        return GeproRestTO.DRAFT_STATUS_TYPE.name === draftStatus;
    }

    isLoading(): boolean {
        return (!this.workListService.gepros || !(this.workListService.gepros.length > 0));
    }

    public isAssignable(gepro: GeproRestTO): boolean {
        return this.statesService.assignableStatusTypes &&
            this.statesService.assignableStatusTypes.filter(state => state.name === gepro.statusType.name).length === 1;
    }

    getMaklerOrStrukturName(item: GeproRestTO) {
        if(item.erfasserBensl=='LSZTECH') {
            return item.msn6 ? item.msn6 : (item.maklerName ? item.maklerName : '-');
        }
        else{
            return item.maklerName ? item.maklerName : (item.msn6 ? item.msn6 : '-');
        }
    }

    private queryGeproStatisticsWithSetParams() {
        const filterParams = this.getFilterParams();
        const relationship = this.getRelationship();

        if (relationship != null && filterParams != null) {
            this.geproFilterStatisticsService.queryGeproStatisticsForFilters(filterParams, relationship)
                .subscribe(data => this.vorgangFilterStatisticsSubject.next(data));
        }
    }

    private getFilterParams() {
        let filterParams = new HttpParams();
        if (this.workListService.quickFilters) {
            const myCurrentFilters = this.workListService.quickFilters.map(e => ({...e}));

            for (const filter of myCurrentFilters) {
                filterParams = filterParams.set(filter.key, filter.value);
            }
        }
        return filterParams;
    }

    private getRelationship(): string {
        if (this.workListService.quickFilters) {
            const relFilter: Filter = this.workListService.quickFilters
                .find(qf => qf.key.toLowerCase() === HEADER_NAME_RELATIONSHIP.toLowerCase());
            return relFilter ? relFilter.value : null;
        }

        return null;
    }

    setFiltersEmitted(filterRequest: GeproFilterRequest): void {
        this.currentPage = 1;
        this.workListService.loading = true;
        this.lastFiltersEmitted = filterRequest;
        this.setFilterCountService.initialFiltersSet(this.getInitialFilterSet(filterRequest));
        this.setFiltersSubject.next(filterRequest);
        if (this.lastFiltersEmitted.getRelationshipFilter() === 'filterNotAssigned') {
            this.showCheckboxColumn = true;
        } else {
            this.showCheckboxColumn = false;
        }
        this.showMarkedColumn = this.isToggleZuteilung();
    }

    private getInitialFilterSet(filterRequest: GeproFilterRequest) {
        let filterParams = new HttpParams();
        if (filterRequest.getStatusTypeFilters()) {
            let filters = filterRequest.getStatusTypeFilters().map(disjunctionPart => disjunctionPart.trim()).sort().join(" OR ");
            filterParams = filterParams.set("statusType", filters);
        }
        return filterParams;
    }

    filterCountChanged(filterCount: number): void {
        this.filterCount = filterCount;
    }

    markGepro(item: GeproRestTO) {
        if(!this.userinfoService.isCurrentUserStvUser()) {
            this.openWiedervorlageDialog(item);
        }
            // this.geproService.markGepro(item.id).then(
            //     _ => {
            //         this.queryGeprosWithSetParams();
            //     }
            // );
    }

    isToggleZuteilung(): boolean {
        // bei Toggle 'Zur Zuteilung' ausblenden
        if (this.lastFiltersEmitted && this.lastFiltersEmitted.getRelationshipFilter() === 'filterNotAssigned' &&
            this.lastFiltersEmitted.getStatusTypeFilters().length === 4 &&
            this.lastFiltersEmitted.getStatusTypeFilters().includes("Manuell") &&
            this.lastFiltersEmitted.getStatusTypeFilters().includes("Offen") &&
            this.lastFiltersEmitted.getStatusTypeFilters().includes("Zurückgezogen") &&
            this.lastFiltersEmitted.getStatusTypeFilters().includes("Zurückgewiesen")) {
            return false;
        }
        return true;
    }

    public workAsStv(selectedStv: StellvertreterTO) {
       this.selectedStv = selectedStv;
       if (selectedStv.bensl === this.userinfoService.getCurrentUserData().bensl) {
           this.userinfoService.resetCurrentUserStvInfo();
           this.worklistTabComponent.refreshTabsAfterStvChange();
       } else {
           this.getSelectedStvUserInfos();
       }
    }

    public getSelectedStvUserInfos() {
        this.stvService.getInfosForSelectesStellvertreter(this.selectedStv.bensl).subscribe(stvInfos => {
            console.log('stvInfos' + stvInfos);
            this.userinfoService.setCurrentUserStvInfo(stvInfos);
            console.log('stvInfos 2' + this.userinfoService.getCurrentStvUser().bensl);
            console.log('stvInfos 3' + this.userinfoService.getUser().bensl);
            this.worklistTabComponent.refreshTabsAfterStvChange();
        }, error => {
            this.userMessagesService.addErrorMessageString('Fehler beim Laden der Informationen zum gewählten Stellvertreter. '
                + error.error.message);
            this.loadStvData();
        });
    }

    getBadgeClass(geproType: StatusTypeRestTO): string {
        if (geproType.displayName === 'Freigegeben') {
            return 'freigegeben';
        } else if (geproType.displayName === 'Zur Freigabe') {
            return 'zurFreigabe';
        } else if (geproType.displayName === 'Nachbearbeitung') {
            return 'nachbearbeitung';
        } else {
            return null;
        }
    }

    openWiedervorlageDialog(item: any): void{
        const dialogRef1 = this.dialog.open(WiedervorlageDialog, {

            data : item
        })
    }

    markedForResubmission(item: GeproRestTO): boolean {
        return item.wiedervorlageMarked === 'true';
    }

    resubmissionDateIsReached(item: GeproRestTO): boolean {
        if (!this.markedForResubmission(item)) {
            return false;
        }

        return moment().isSameOrAfter(moment(item.wiedervorlageDate, 'DD.MM.YYYY'));
    }
}

export type Finish = 'Abort' | 'Update' | 'Create';
@Component({
    selector: 'wiedervrlage-dialog',
    templateUrl: './wiedervorlage-dialog.html',
    styleUrls: ['./work-list.component.scss'],
    providers: [
        {provide: NxDatepickerIntl, useClass: MyIntl}
    ]
})
export class WiedervorlageDialog implements OnInit{
    itemVariable : any;
    wiedervorlage : Wiedervorlage;
    public inputDone: boolean =false;
    public inputDoneDate: boolean = false;
    @ViewChild('fileUpload', {read: ElementRef, static: false})
    fileUpload: ElementRef<any>;
    @Output()
    readonly finished: Subject<Finish>;
    document: any;
    private pdfFormat = '.pdf';
    public checkDatum :boolean;
    public erstellerTemplate : any;
    public bearbeiterTemplate : any;
    constructor(public dialogRef: MatDialogRef<WiedervorlageDialog>, @Inject(MAT_DIALOG_DATA) public item:any, public wiedervorlageService: WiedervorlageService, public worklistcomponent:WorkListComponent
    ,public userinfoService: UserinfoService) {

    }

    ngOnInit() {
        this.wiedervorlage = {
            id: undefined,
            bensl: undefined,
            beschreibung: '',
            checkbox: 'false',
            geproid: this.item.id,
            updated: null,
            wiedervorlageDocuments: undefined
        }
        this.erstellerTemplate = this.item.erfasserBensl;
        this.bearbeiterTemplate = this.item.mitarbeiterBensl;
        console.log(this.item.erfasserBensl)
        console.log(this.item)
        console.log(this.item.mitarbeiterBensl)
        console.log(this.userinfoService.getUser().bensl)
        this.wiedervorlageService.getWiedervorlage(this.wiedervorlage.geproid).subscribe(wid=>{
            if(wid!=null){
                this.wiedervorlage=wid;
                // if (this.wiedervorlage!=null) {
                //     this.loadDocuments(this.wiedervorlage.id);
                //     if (this.wiedervorlage.wiedervorlageDocuments == undefined) {
                //         this.wiedervorlage.wiedervorlageDocuments = new Array<FileList>();
                //     }
                // }
            }
        })
    }
    onCreationSuccess(): void {
        this.finished.next('Abort');
    }

    removeDocument(document: any) {
        this.wiedervorlageService.removeDocument(document).subscribe(documentList => {
                this.wiedervorlage.wiedervorlageDocuments = documentList;
                this.wiedervorlage.wiedervorlageDocuments.sort((doc1, doc2) => (doc1.name > doc2.name ? 1 : -1));
            },
            error => {
                console.log(error);
            });
    }

    onCheckboxClicked($event: Event): void {
        $event.stopPropagation();
    }

    public isCheckboxChecked() {
     return this.wiedervorlage.checkbox.indexOf('true')>-1;
    }

    public onCheckboxChanged(){

         if(this.wiedervorlage.checkbox.indexOf('true')>-1){
            this.wiedervorlage.checkbox= 'false';
        }
        else if(this.wiedervorlage.checkbox.indexOf('false')>-1){
            this.wiedervorlage.checkbox= 'true';
        }
        else{
            this.wiedervorlage.checkbox = 'true';
        }
    }

    public downloadDocument(wid: any) {
        this.wiedervorlageService.getWiedervorlageDocuments(wid.id, wid.geproid)
            .subscribe(
                data => {
                    this.downloadFile(data, wid);
                }
            );
    }

    private downloadFile(data: any, hin: any) {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(data.file);
        element.download = hin.name;
        document.body.appendChild(element);
        element.click();
    }


    onUpdateSuccess(): void {
        // this.finished.next('Abort');
    }

    onAbort(): void {
        this.finished.next('Abort');
    }
    persist(): void {
        this.close();
        // if(this.wiedervorlage.id!=null) {
            this.wiedervorlageService.update(this.wiedervorlage).subscribe(_ => this.onUpdateSuccess());
        console.log("7");
        this.worklistcomponent.queryGeprosWithSetParams();
        // }
        // else{
        //     this.wiedervorlageService.create(this.wiedervorlage).subscribe(_ => this.onUpdateSuccess());
        // }
    }

    delete(): void {
        this.wiedervorlageService.delete(this.wiedervorlage).subscribe(_ => this.close());
        console.log("8");
        this.worklistcomponent.queryGeprosWithSetParams();
    }

    private loadDocuments(id: number) {
        this.wiedervorlageService.getWiedervorlageDocuments(id, this.wiedervorlage.geproid)
            .subscribe(
                documentList => {
                    this.wiedervorlage.wiedervorlageDocuments = documentList;
                    this.wiedervorlage.wiedervorlageDocuments.sort((doc1, doc2) => (doc1.name > doc2.name ? 1 : -1));
                },
                error => {
                    console.log(error);
                }
            );
    }

    close():void{
        this.dialogRef.close();
    }

    onChangeInput() {
        this.inputDone = true;
        console.log(this.inputDone + " inputDone")
    }

    onChangeDate() {
    }
    public onDrop(event: any): void {
        if(event.dataTransfer.files[0].name.indexOf('.pdf')>-1) {

            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
            event.stopPropagation();
            const files = event.dataTransfer.files;

            if(files[0].name.indexOf('.pdf')>-1) {
                if ((this.wiedervorlage.wiedervorlageDocuments != undefined && this.wiedervorlage.wiedervorlageDocuments.length > 0)) {
                    this.wiedervorlageService.uploadDocument(this.wiedervorlage.id, this.wiedervorlage.geproid,this.wiedervorlage.bensl,files[0]).subscribe(
                        document => {
                            this.wiedervorlage.wiedervorlageDocuments.push(document);
                        }
                    );
                } else {
                    this.wiedervorlageService.create(this.wiedervorlage).subscribe(wid => {
                        this.wiedervorlage = wid;
                        this.wiedervorlageService.uploadDocument(this.wiedervorlage.id, this.wiedervorlage.geproid,this.wiedervorlage.bensl, files[0]).subscribe(
                            document => {
                                this.wiedervorlage.wiedervorlageDocuments.push(document);
                            }
                        )
                    });
                }
            }
            this.onChangeInput();
        }
    }

    public onClickDocument(files: FileList): void {
        if(files[0].name.indexOf('.pdf')>-1) {
            if ((this.wiedervorlage.wiedervorlageDocuments != undefined && this.wiedervorlage.wiedervorlageDocuments.length > 0)) {
                this.wiedervorlageService.uploadDocument(this.wiedervorlage.id, this.wiedervorlage.geproid,this.wiedervorlage.bensl, files[0]).subscribe(
                    document => {
                        this.wiedervorlage.wiedervorlageDocuments.push(document);
                    }
                );
            } else {
                this.wiedervorlageService.create(this.wiedervorlage).subscribe(wid => {
                    this.wiedervorlage = wid;
                    this.wiedervorlageService.uploadDocument(this.wiedervorlage.id, this.wiedervorlage.geproid,this.wiedervorlage.bensl, files[0]).subscribe(
                        document => {
                            this.wiedervorlage.wiedervorlageDocuments.push(document);
                        }
                    )
                });
            }
        }
    }
    isSuccessDocument(): boolean {
        return this.document && this.document.status === 'SUCCESS';
    }

    isFailure(): boolean {
        return this.document && this.document.status === 'FAILURE';
    }

    isUploading(): boolean {
        return this.document && this.document.status === 'UPLOADING';
    }

    isQueued(): boolean {
        return this.document && this.document.status === 'WAITING';
    }
    checkDate(): void {
        if(this.wiedervorlage.updated!=null) {
            this.checkDatum = false;
            this.wiedervorlageService
                .checkDate(this.wiedervorlage)
                .subscribe(res => {
                    if (res == false) {
                        this.wiedervorlage.updated = null;
                        this.checkDatum = true;
                    }
                    else if(res == true){
                        this.inputDoneDate = true;
                        console.log(this.inputDoneDate + " inputDoneDate")
                    }
                });
        }
    }

    public onDragOver(event: any): void {
        event.dataTransfer.dropEffect = 'copy';
        event.preventDefault();
        event.stopPropagation();
    }

    public onDragLeave($event): void {
        $event.preventDefault();
        $event.stopPropagation();
    }
    openFileInputDialog() {
        this.fileUpload.nativeElement.click();
    }
    acceptedFileTypes(): string {
        return this.pdfFormat;
    }


}
