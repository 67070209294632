<ng-template #loadingTemplate>
    <nx-spinner style="text-align: center;margin-left: auto;
    margin-right: auto;" nxSize="large"></nx-spinner>
</ng-template>

<nx-card class="wl-header">
    <div class="headline">
        <h1>Arbeitsliste von</h1>
    </div>
    <nx-formfield class="stvList">
        <nx-dropdown (nxValueChange)="workAsStv($event)" [(nxValue)]="selectedStv">
            <nx-dropdown-item *ngFor="let stv of stvListDropdown" [nxValue]="stv">{{stv.fullname}}</nx-dropdown-item>
        </nx-dropdown>
    </nx-formfield>

    <app-vorgang-search (onLoad)="goToGepro($event)" role="search" class="search-input"></app-vorgang-search>
</nx-card>

<div class="margined-container" >
    <app-work-list-tabs (setFilterRequested)="setFiltersEmitted($event)"></app-work-list-tabs>
    <app-gepro-filter (filterChanged)="onFilter($event)" [hidden]="hideFilters" [filterStatisticsObservable]="vorgangFilterStatisticsSubject.asObservable()" [setFilterObservable]="setFiltersSubject.asObservable()"></app-gepro-filter>
</div>
<br>
<div class="filterButtonsWrapper" *ngIf="!workListService.loading; else loadingTemplate">
    <div>
        <button class="btAnzahl" nxButton="tertiary small" type="button">
            {{workListService.totalQueriedElements}} {{workListService.totalQueriedElements === 1 ? 'Vorgang' : 'Vorgänge'}} gefunden
        </button>
    </div>
    <div class="emptyLeft"></div>
    <div>
        <button class="leftRightButton" *ngIf="filterCount>0 && filterVisibilityService.isAnyFilterVisible()"
                (click)="resetFilter()" nxButton="tertiary small" type="button">
            {{filterCount}} {{filterCount === 1 ? 'gesetzten' : 'gesetzte'}} Filter zurücksetzen
            <nx-icon class="nx-margin-right-2xs" name="refresh"></nx-icon>
        </button>
<!--        <button class="floatRightButton" *ngIf="filterVisibilityService.isAnyFilterVisible()"-->
<!--                nxButton="tertiary small" type="button" (click)="hideFilters=!hideFilters">-->
<!--            Filter {{hideFilters ? 'öffnen' : 'schließen'}}-->
<!--            <nx-icon name="{{hideFilters? 'chevron-down': 'chevron-up'}}" class="nx-margin-right-2xs"></nx-icon>-->
<!--        </button>-->

    </div>
    <button style="float:right" nxButton="tertiary small" type="button" (click)="downloadCSV()">
        <nx-icon name="download" class="nx-margin-right-2xs"></nx-icon>Arbeitsliste herunterladen
    </button>
</div>

<div class="margined-container" role="Arbeitsliste" >

    <table nxTable nxSort (sortChange)="sort($event)" >
        <thead>
        <tr nxTableRow>
            <th *ngIf="showCheckboxColumn" class="cell-checkbox" nxHeaderCell>
                <nx-checkbox [name]="'Eintrag auswählen'"
                    [disabled]="!isAssignableStatusSelected() && !isInProgressStatusSelected()"
                    (checkedChange)="workListService.toggleAll()"
                    [checked]="workListService.selectedGepros.hasValue() && workListService.isAllSelected()"
                    [indeterminate]="workListService.selectedGepros.hasValue() && !workListService.isAllSelected()">
                </nx-checkbox>
            </th>
            <th (click)="sortById()" class="cell-id" nxHeaderCell>
                ID
                <span *ngIf="sortOrder=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrder=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrder=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByName()" class="cell-geproType" nxHeaderCell>
                Vorgang
                <span *ngIf="sortOrderName=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderName=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderName=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByVermittler()" class="cell-id" nxHeaderCell>
                Vermittler
                <span *ngIf="sortOrderVermittler=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderVermittler=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderVermittler=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th class="cell-id" nxHeaderCell>
                Zusatzinfo
            </th>
            <th *ngIf="displayMitarbeiterColumn()" (click)="sortByMitarbeiterName()" class="cell-mitarbeiter" nxHeaderCell>
                Bearbeiter
                <span *ngIf="sortOrderMitarbeiterName=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderMitarbeiterName=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderMitarbeiterName=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByTimestamp()" class="cell-timestamp" nxHeaderCell>
                Eingereicht
                <span *ngIf="sortOrderPublished=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderPublished=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderPublished=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByChanged()" class="cell-timestamp" nxHeaderCell>
                Geändert
                <span *ngIf="sortOrderChanged=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderChanged=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderChanged=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th class="cell-status" nxHeaderCell>
                Status
            </th>
            <th *ngIf="showMarkedColumn" class="cell-mark" nxHeaderCell nxSortHeaderCell="geproMarkedList.createdTs">
                Wiedervorlage
            </th>
            <th class="cell-context-header" nxHeaderCell></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isLoading()">
            <td colspan="5"><!-- Loading state planned... --> &nbsp;</td>
        </tr>
        <tr class="cell-checkbox" nxTableRow *ngFor="let item of workListService.gepros"
            appWorkListDragStart [item]="item" [draggable]="isAssignable(item)">
            <td *ngIf="showCheckboxColumn" nxTableCell class="tableCheckbox">

                <nx-checkbox
                    [disabled]="!isAssignableStatusSelected() && !isInProgressStatusSelected() && !visibilityService.isEnabled('assign', item)"
                    (click)="$event.stopPropagation()"
                    (checkedChange)="workListService.selectedGepros.toggle(item)"
                    [checked]="workListService.selectedGepros.isSelected(item)">
                    <span style="display: none">Eintrag auswählen</span><!--For label purposes-->
                </nx-checkbox>
            </td>
            <td nxTableCell class="cell-id" (click)="goToGepro(item)">{{item.id}}</td>
            <td class="cell-geproType" nxTableCell (click)="goToGepro(item)">{{item.geproType?.name}}</td>
            <td (click)="goToGepro(item)" class="cell-id" nxTableCell>{{getMaklerOrStrukturName(item)}}</td>
            <td (click)="goToGepro(item)" class="cell-id" nxTableCell>{{item.zusatzInfo}}</td>
            <td *ngIf="displayMitarbeiterColumn()" class="cell-mitarbeiter"
                nxTableCell (click)="goToGepro(item)">
                <span *ngIf="item.mitarbeiterName; else noMitarbeiter">{{ item.mitarbeiterName }}</span>
                <ng-template #noMitarbeiter>
                    <span class="cell-mitarbeiter">-</span>
                </ng-template>
            </td>
            <td class="cell-timestamp"
                nxTableCell (click)="goToGepro(item)">
                <app-timestamp [timestamp]="isDraft(item.statusType?.name) ? undefined : item?.publishTimestamp" [timestampStringTwoLines]="true"></app-timestamp>
            </td>
            <td class="cell-timestamp" nxTableCell (click)="goToGepro(item)">
                <app-timestamp [timestamp]="item?.changeTimestamp" [timestampStringTwoLines]="true"></app-timestamp>
            </td>
            <td class="cell-status" nxTableCell (click)="goToGepro(item)">
                <nx-badge [vibrant]="isVibrant(item.statusType)" [ngClass]="getBadgeClass(item.statusType)"
                          [type]="item.statusType.badgeName">{{item.statusType.displayName}}</nx-badge>
            </td>
            <td *ngIf="showMarkedColumn" class="cell-mark" nxTableCell (click)="markGepro(item);"
                 title="{{markedForResubmission(item)?item.wiedervorlageBeschreibung:''}}">

                <nx-icon *ngIf="markedForResubmission(item) && !resubmissionDateIsReached(item)"
                         aria-label="Vorgang markiert"
                         name="star" size="s" class="bookmark_al"></nx-icon>
                <nx-icon *ngIf="markedForResubmission(item) && resubmissionDateIsReached(item)"
                         aria-label="Vorgang markiert"
                         name="star" size="s" class="bookmark_al_red"></nx-icon>
                <nx-icon *ngIf="!markedForResubmission(item)"
                         aria-label="Vorgang nicht markiert"
                         name="star-o" size="s" class="bookmark_al"></nx-icon>
                <span style="float: right; margin-top: 2px" *ngIf="item.wiedervorlageUpdated">{{ item.wiedervorlageUpdated | date: 'dd.MM.yyyy' }}</span>
            </td>
            <td class="cell-context" nxTableCell>
                <work-list-contextmenu-cell [selectedGepro]="item"
                                            (openGepro)="goToGepro(item)">
                </work-list-contextmenu-cell>
            </td>

        </tr>
        </tbody>
        <tfoot>
        <tr nxTableRow>
            <th *ngIf="showCheckboxColumn" class="cell-checkbox" nxHeaderCell>
                <nx-checkbox [name]="'Eintrag auswählen'"
                             [disabled]="!isAssignableStatusSelected() && !isInProgressStatusSelected()"
                             (checkedChange)="workListService.toggleAll()"
                             [checked]="workListService.selectedGepros.hasValue() && workListService.isAllSelected()"
                             [indeterminate]="workListService.selectedGepros.hasValue() && !workListService.isAllSelected()">
                </nx-checkbox>
            </th>
            <th (click)="sortById()" class="cell-id" nxHeaderCell>
                ID
                <span *ngIf="sortOrder=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrder=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrder=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByName()" class="cell-geproType" nxHeaderCell>
                Vorgang
                <span *ngIf="sortOrderName=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderName=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderName=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByVermittler()" class="cell-id" nxHeaderCell>
                Vermittler
                <span *ngIf="sortOrderVermittler=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderVermittler=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderVermittler=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th class="cell-id" nxHeaderCell>
                Zusatzinfo
            </th>
            <th *ngIf="displayMitarbeiterColumn()" (click)="sortByMitarbeiterName()" class="cell-mitarbeiter" nxHeaderCell>
                Bearbeiter
                <span *ngIf="sortOrderMitarbeiterName=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderMitarbeiterName=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderMitarbeiterName=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByTimestamp()" class="cell-timestamp" nxHeaderCell>
                Eingereicht
                <span *ngIf="sortOrderPublished=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderPublished=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderPublished=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th (click)="sortByChanged()" class="cell-timestamp" nxHeaderCell>
                Geändert
                <span *ngIf="sortOrderChanged=='default'"><nx-icon aria-hidden="true" name="popout"></nx-icon></span>
                <span *ngIf="sortOrderChanged=='asc'"><nx-icon aria-hidden="true" name="arrow-up"></nx-icon></span>
                <span *ngIf="sortOrderChanged=='desc'"><nx-icon aria-hidden="true" name="arrow-down"></nx-icon></span>
            </th>
            <th class="cell-status" nxHeaderCell>
                Status
            </th>
            <th *ngIf="showMarkedColumn" class="cell-mark" nxHeaderCell nxSortHeaderCell="geproMarkedList.createdTs">
                Wiedervorlage
            </th>
            <th class="cell-context-header" nxHeaderCell></th>
        </tr>
        </tfoot>
    </table>

    <div fxLayoutAlign="center">
<!--        <p nxCopytext="small" id="pageSizeLabel">Elemente pro Seite</p>-->
        <nx-formfield id="pageSizeFormfield" nxLabel="Anzahl" appearance="outline">
            <nx-dropdown (nxValueChange)="pageSize($event)" id="pageSizeDropdown" [(nxValue)]="elementsPerPage">
                <nx-dropdown-item [nxValue]="25"></nx-dropdown-item>
                <nx-dropdown-item [nxValue]="50"></nx-dropdown-item>
                <nx-dropdown-item [nxValue]="150"></nx-dropdown-item>
                <nx-dropdown-item [nxValue]="250"></nx-dropdown-item>
            </nx-dropdown>
        </nx-formfield>

        <nx-pagination
                       class="margin-top"
                       [nxPage]="currentPage"
                       [nxPerPage]="elementsPerPage"
                       [nxCount]="workListService.totalQueriedElements"
                       nxType="advanced"
                       (nxGoPrev)="previousPage()"
                       (nxGoNext)="nextPage()"
                       (nxGoPage)="goToPage($event)">
        </nx-pagination>
    </div>

    <ng-container
        *ngTemplateOutlet="actionButtons">
    </ng-container>

    <ng-template #actionButtons>
        <!--SPARTA-1452: Button für MVP1 ausblenden
        <button class="btDeny" type="button" nxButton="secondary small-medium"
                *ngIf="isInProgressStatusSelected() && !userinfoService.isRoleReferatsleiter() && userinfoService.isRoleAmvbMA()"

            button class="btDeny" type="button" nxButton="secondary small-medium"
                *ngIf="!userinfoService.isRoleReferatsleiter() && userinfoService.isRoleAmvbMA()"
                (click)="workListService.denySelectedGepros()"
                [disabled]="workListService.isDenyButtonDisabled()">
            Selektierte zurückweisen
        </button-->
        <button class="btAuthorise" nxButton='primary small-medium nx-margin-top-s'
                *ngIf="userinfoService.isRoleReferatsleiter()"
                (click)="workListService.authoriseSelectedGepro()"
                [disabled]="workListService.isAuthoriseButtonDisabled()">
            Selektierte freigeben
        </button>
    </ng-template>
</div>


<app-dnd-sidebar></app-dnd-sidebar>


<app-move-gepro-referat></app-move-gepro-referat>
<app-deny-gepro></app-deny-gepro>
